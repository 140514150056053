<template>
	<div>
		<div class="p-theallhead-posi">
		</div>
		<div :class="['p-theallhead', showhead==2 ? 'p-theallheadhigt':'']">
			<div :class="['', showhead==2 ? 'p-theallheadtop':'']">
				<login ref='child'></login>
				<div :class="['p-fix-head', showhead==2 ? 'p-fix-head p-theallheadtop':'']" v-if="seacrh==1" @mouseleave="seacrh=2">
					<div v-if="!thesearch">
						<div class="p-fix-div">
							<div class="p-fix-left">历史记录</div>
							<div class="p-fix-right"><img src="../../assets/img/home/delete.png" /><span @click="clean">清空</span></div>
						</div>
						<div class="p-fix-item" v-for="(item,index) in history" :key="index">
							<div @click="tosearch(item)">{{item}}</div>
						</div>
						<div class="p-fix-div">
							<div class="p-fix-left">热门搜索</div>
						</div>
						<div class="p-fix-item" v-for="(item,index) in keyword" :key="item">
							<div @click="tosearch(item)">{{item}}</div>
						</div>
					</div>
					<div v-if="thesearch">
						<div class="p-fix-div">
							<div class="p-fix-left">你可能想找</div>
						</div>
						<div class="p-fix-item" v-for="(item,index) in recommend" :key="index">
							<div @click="tosearch(item)">{{item}}</div>
						</div>
					</div>
				</div>
				<div v-if="login==1" @mouseenter="login=1" @mouseleave="login=2" :class="['p-head-login-ext', showhead==2 ? 'p-head-login-ext p-theallheadlogin':'']">
					<div class="p-head-login">
						<div style="color: #00D0B0;">正在用{{whichlogin}}</div>
						<div class="p-head-l-line"></div>
						<div @click="tomy(1)">我的课程</div>
						<div @click="tomy(2)">我的订单</div>
						<div @click="tomy(3)">我的收藏</div>
						<div @click="tomy(4)">优惠券</div>
						<div @click="tomy(5)">设置</div>
						<div></div>
						<div @click="eixtlogin">退出登录</div>
					</div>
				</div>
				<div :class="['p-head-class', showhead==2 ? 'p-head-class p-theallheadclass':'']" v-if="showclass==1" @mouseleave="showclass=0">
					<div class="p-h-c-left">
						<div :class="['p-h-c-item', choose==index ? 'p-h-c-item p-h-c-item-c':'']" v-for="(item,index) in headclass" :key="index"
						 @click="cchoose(index,1)" @mouseenter="cchoose(index,2)">
							<div>{{item.cat_name}}</div>
							<img src="../../assets/img/my/left.png" v-if="choose==index " />
							<img src="../../assets/img/courseDetail/right.png" v-if="choose!=index " />
						</div>
					</div>
					<div class="p-h-c-right">
						<div class="p-h-c-r-head">
							<div :class="['p-h-c-r-title', twochoose==indexa ? 'p-h-c-r-title p-h-c-r-title-c':'']" v-for="(item,indexa) in headclass[choose].children"
							 :key="indexa" @click="ctwochoose(indexa)">
								{{item.cat_name}}
							</div>
						</div>
						<div class="p-h-c-r-span">
							<div :class="['p-h-c-r-item', threechoose==indexaa ? 'p-h-c-r-item p-h-c-r-item-c':'']" v-for="(item,indexaa) in headclass[choose].children[twochoose].children"
							 :key="indexaa" @click="cthreechoose(indexaa)">{{item.cat_name}}</div>
						</div>
					</div>
				</div>
				<div class="p-headclude">
					<div @click="chooseclass(1)">
						<img class="p-headlogo" src="../../assets/img/home/logo.png" />
						<img class="p-headtitle" src="../../assets/img/home/yjkt.png"></img>
					</div>
					<div class="p-headtype" @mouseenter="chooseclass(2)"><i class="el-icon-lx-cascades mr4"></i>
						<span class="el-dropdown-link">
							课程分类<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
					</div>
					<div class="p-headseach">
						<div class="p-head-ed">
							<span>课程</span><i class="el-icon-arrow-down el-icon--right"></i>
						</div>
						<span></span>
						<img src="../../assets/img/home/search.png" />
						<input @input="showsearch()" maxlength="20" v-model="thesearch" />
						<div class="p-headseach-s" @click="chooseclass(3)">搜索</div>
					</div>
					<div class="p-headtype ml60" @click="chooseclass(4)" v-if="isauth==0">教学入驻</div>
					<div class="p-headtype ml60" @click="chooseclass(4)" v-if="isauth==1">管理后台</div>
					<div class="p-headtype ml60" @click="chooseclass(4)" v-if="isauth==2">机构审核</div>
					<div class="p-headtype ml60" @click="chooseclass(5)">会员中心</div>
					<div class="p-headtype ml28">
						<i class="el-icon-bell" @click="chooseclass(6)">
							<el-badge is-dot v-if="messagecount!=0"></el-badge>
						</i>
						<i class="el-icon-shopping-cart-full ml28" @click="chooseclass(7)">
							<el-badge :value="mycarcount" v-if="mycarcount!=0"></el-badge>
						</i>
						<img v-if="!img" @click="chooseclass(8)" src="../../assets/img/home/unlogin.png" class="p-imagelogo"></img>
						<img v-if="img" :src="img" class="p-imagelogo" @mouseenter="login=1"></img>
						<div class='p-head-class-name' v-if="!name" @click="chooseclass(8)">登录/注册</div>
						<div class='p-head-class-name' v-if="name" @mouseenter="login=1">{{name}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import login from './login.vue';
	export default {
		components: {
			login
		},
		data() {
			return {
				showhead: 1,
				choose: 0,
				name: "",
				img: "",
				seacrh: 0,
				login: 2,
				img: "",
				twochoose: 0,
				threechoose: 0,
				headclass: [],
				showclass: 0,
				showjujiao: "",
				keyword: [],
				thesearch: "",
				history: [],
				recommend: [],
				whichlogin: "账号",
				isauth: 0,
				auth: 0,
				mycarcount: 0,
				messagecount: 0,
				token: "",
			};
		},
		watch: {
			'$route': function() {
				this.name = localStorage.getItem("name")
				this.img = localStorage.getItem("img")
				this.token = localStorage.getItem("token")
				this.mycarcount = localStorage.getItem("buycarcount")
				window.addEventListener('scroll', this.handleScroll, true)
			},
		},
		mounted() {
			this.getgoodscate()
			if (localStorage.getItem("token")) {
				this.name = localStorage.getItem("name")
				this.img = localStorage.getItem('img')
				this.token = localStorage.getItem("token")
				this.whichlogin = localStorage.getItem('whichlogin')
				this.mycarcount = localStorage.getItem("buycarcount")
			}
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods: {
			//监听鼠标滚动
			handleScroll() {
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				if (top > 200) {
					this.showhead = 2
				}
				if (top < 200) {
					this.showhead = 1
				}
			},
			chooseclass(e) {
				if (!this.name) {
					//父组件使用子组件的办法
					localStorage.removeItem("token")
					this.$refs.child.toshow(3);
					return
				}
				if (e == 1) {
					this.$router.push({
						path: '/home',
						query: {
							id: e
						}
					})
				}
				if (e == 2) {
					this.showclass = 1
				}
				if (e == 3) {
					this.tosearch(this.thesearch)
				}
				if (e == 4) {
					if (this.isauth == 0) {
						this.$router.push({
							path: '/enter',
							query: {
								id: e
							}
						})
						return
					}
					if (this.isauth == 2) {
						this.$router.push({
							path: '/stepThree',
							query: {
								e: this.auth
							}
						})
						return
					}
					window.open("https://ykt.qdy2020.com/a/#/login", "_blank");

				}
				if (e == 5) {
					this.$router.push({
						path: '/vip',
						query: {
							id: e
						}
					})
				}
				if (e == 6) {
					localStorage.setItem("messagecount", 0)
					this.$router.push({
						path: '/message',
						query: {
							id: e
						}
					})
				}
				if (e == 7) {
					this.$router.push({
						path: '/buycar',
						query: {
							id: e
						}
					})
				}
			},
			eixtlogin() {
				this.name = ""
				this.img = ""
				localStorage.removeItem("whichlogin")
				localStorage.removeItem("token")
				localStorage.removeItem("name")
				localStorage.removeItem("img")
				localStorage.removeItem("is_auth")
				this.$router.push({
					path: '/home',
				})
			},
			setname(name, img, which, isauth, auth, mycarcount, messagecount) {
				this.name = name
				this.img = img
				this.whichlogin = which
				this.isauth = isauth
				this.auth = auth
				this.mycarcount = mycarcount
				this.messagecount = messagecount
			},
			tomy(e) {
				this.$router.push({
					path: '/my',
					query: {
						id: e
					}
				})
			},
			tosearch(key) {
				this.seacrh = 0
				this.thesearch = key
				this.$router.push({
					path: '/search',
					query: {
						key: key
					}
				})
			},
			clean() {
				this.keyword = []
				this.deletesearch()
			},
			showsearch() {
				if (!localStorage.getItem("token")) {
					this.$message.error("请登录")
					return
				}
				this.seacrh = 1
				this.showclass = 0
				this.gethotkeyword()
				this.getgoodsbykeyword()
			},
			gethistory() {
				this.$get("index/getusersearchhistory", {}).then(response => {
					if (response.error == 0) {

					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getgoodscate(data) {
				var that = this
				if (!localStorage.getItem("token")) {
					this.$refs.child.toshow(7);
				}
				this.headclass = data
			},
			cchoose(e, d) {
				this.choose = e
				this.twochoose = 0
				if (d == 1) {
					this.$router.push({
						path: '/search',
						query: {
							id: e,
							choose: this.choose,
							data: this.headclass,
						}
					})
				}
			},
			ctwochoose(e) {
				this.twochoose = e
				this.$router.push({
					path: '/search',
					query: {
						id: e,
						choose: this.choose,
						twochoose: this.twochoose,
						data: this.headclass,
					}
				})
			},
			cthreechoose(e) {
				this.threechoose = e
				this.$router.push({
					path: '/search',
					query: {
						id: e,
						choose: this.choose,
						twochoose: this.twochoose,
						threechoose: this.threechoose,
						data: this.headclass,
					}
				})
			},
			//删除搜索记录
			deletesearch() {
				this.$get("index/delsearchhistory", {}).then(response => {
					if (response.error == 0) {
						this.$message.success(response.msg)
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			gethotkeyword(e) {
				this.$get("index/getusersearchhistory", {}).then(response => {
					if (response.error == 0) {
						this.keyword = response.data.hotWords
						this.history = response.data.historyWords
					} else {
						this.$message.error(response.msg)
						return
					}
				});
			},
			getgoodsbykeyword() {
				this.$get("index/goodsbykeyword", {
					keyword: this.thesearch
				}).then(response => {
					if (response.error == 0) {
						this.recommend = response.data.goodsNames
					} else {
						this.$message.error(response.msg)
						return
					}
				});

			}
		},

	};
</script>

<style>
	.p-theallhead-posi {
		width: 100%;
		height: 100px;
		position: relative;
		z-index: 300;
		top: 0px;
		line-height: 100px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif !important;
	}

	.p-theallhead {
		width: 100%;
		height: 100px;
		position: fixed;
		z-index: 300;
		top: 0px;
		background: #FFFFFF;
		line-height: 100px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif !important;
	}

	.p-theallheadhigt {
		width: 100%;
		height: 60px;
		position: fixed;
		z-index: 300;
		top: 0px;
		background: #FFFFFF;
		line-height: 100px;
		opacity: 0.95;
		box-shadow: 0px 1px 16px 0px rgba(0, 0, 0, 0.15);
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif !important;
	}

	.p-theallheadtop {
		margin-top: -20px;
	}

	.p-headclude {
		width: 80%;
		margin-left: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		font-size: 16px;
		color: #333333;
		margin-right: auto;
	}

	.p-headclude div :hover {
		color: #00D0B0;
	}

	.p-headclude i {
		margin-top: 42px;
	}

	.p-headclude span {
		margin-left: 4px;
	}

	.p-headtype {
		float: left;
		margin-left: 15px;
		cursor: pointer;
		color: #333333;
		display: flex;
	}

	.p-headseach {
		height: 44px;
		display: flex;
		flex-flow: row wrap;
		line-height: 44px;
		align-items: flex-start;
		border-bottom: 1px solid #D2D2D2;
		border-top: 1px solid #D2D2D2;
		border-left: 1px solid #D2D2D2;
		border-radius: 22px;
		float: left;
		margin-top: 28px;
		margin-left: 129px;
	}

	.p-headseach input {
		font-size: 14px;
	}

	.p-headlogo {
		width: 56px;
		height: 56px;
		font-size: 16px;
		float: left;
		margin-top: 22px;
		cursor: pointer;
		border-radius: 50%;
	}

	.p-headtitle {
		font-size: 32px;
		font-weight: 400;
		float: left;
		color: #333333;
		margin-left: 15px;
		margin-top: 33px;
		width: 136px;
		height: 32px;
		cursor: pointer;
	}

	.ml83 {
		margin-left: 83px;
	}

	.mr4 {
		margin-right: 4px;
		margin-left: 19px;
	}

	.ml41 {
		margin-left: 41px;
	}

	.ml28 {
		margin-left: 18px;
	}

	.p-imagelogo {
		height: 38px;
		width: 38px;
		border-radius: 50%;
		margin-left: 32px;
		position: relative;
		margin-top: 31px;
	}

	.p-headseach span {
		width: 1px;
		height: 36px;
		background: #E6E6E6;
		margin-top: 4px;
		margin-left: 15px;
		margin-right: 12px;
	}

	.p-head-ed {
		margin-top: -26px;
	}

	.p-head-ed span {
		background: none !important;
	}

	.p-headseach img {
		width: 16px;
		height: 16px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.p-headseach input {
		width: 207px;
		height: 22px;
		margin-top: auto;
		margin-bottom: auto;
		margin-left: 10px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		background: #FFFFFF;
		border: none;
	}

	.tc {
		color: #00D0B0;
	}

	.p-headseach-s {
		width: 78px;
		height: 44px;
		background: #00D0B0;
		border-radius: 0px 22px 22px 0px;
		text-align: center;
		font-size: 16px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #FFFFFF !important;
		cursor: pointer;
	}

	/* 搜索状态出来 */
	.p-fix-head {
		position: absolute;
		top: 74px;
		width: 394px;
		height: 316px;
		overflow-y: scroll;
		background: #FFFFFF;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
		left: 749px;
		z-index: 300 !important;
		line-height: 20px !important;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #999999;

	}

	.p-fix-div {
		width: 100%;
		height: 41px;
	}

	.p-fix-left {
		float: left;
		margin-top: 20px;
		margin-left: 10px;
	}

	.p-fix-right {
		float: right;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-top: 20px;
		margin-right: 10px;
		cursor: pointer;
	}

	.p-fix-right :hover {
		color: #00D0B0;
	}

	.p-fix-right img {
		width: 12px;
		height: 12px;
		margin-top: auto;
		margin-bottom: auto;
	}

	.p-fix-item {
		width: 99%;
		height: 34px;
		background: #FFFFFF;
		line-height: 34px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		cursor: pointer;
	}

	.p-fix-item :hover {
		background: #F9F9F9 !important;
		color: #00D0B0;
	}

	.p-fix-item div {
		width: 96%;
		margin-left: 2%;
		font-size: 14px;
		color: #333333;
	}

	.p-head-login-ext {
		width: 170px;
		height: 380px;
		position: absolute;
		left: 1565px;
		z-index: 200;
		top: 8px;
	}

	.p-head-login {
		width: 161px;
		height: 264px;
		background: #FFFFFF;
		border: 1px solid #F3F3F3;
		position: absolute;
		margin-top: 78px;
		z-index: 300;
		font-size: 12px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #00D0B0;
		text-align: center;
		line-height: 38px;
		color: #333333;
	}

	.p-head-login :hover {
		color: #00D0B0;
		cursor: pointer;
	}

	.p-head-l-line {
		width: 139px;
		height: 1px;
		background: #F3F3F3;
	}

	/* 分类选择 */
	.p-head-class {
		position: absolute;
		top: 75px;
		background: #FFFFFF;
		z-index: 300;
		width: auto;
		margin-left: 463px;
		height: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15);
		cursor: pointer;
	}

	.p-h-c-left {
		width: auto;
		height: auto;
		border-right: 1px solid #E6E6E6;
	}

	.p-h-c-item {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-top: 25px;
		margin-bottom: 25px;
		font-size: 14px;
		width: 100%;
		height: 25px;
		line-height: 25px;
		font-family: 'PingFang SC', "Helvetica Neue", Helvetica, "microsoft yahei", arial, STHeiTi, sans-serif;
		font-weight: 400;
		color: #333333;
	}

	.p-h-c-item-c {
		color: #00D0B0;
	}

	.p-h-c-item div {
		margin-left: 21px;
		margin-right: 17px;
	}

	.p-h-c-item img {
		width: 6px;
		height: 10px;
		margin-bottom: auto;
		margin-right: 5px;
		margin-top: auto;
	}

	.p-h-c-right {
		height: auto;
		margin-top: 38px;
		font-size: 14px;
		width: auto;
		line-height: 14px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		color: #666666;
	}

	.p-h-c-r-head {
		width: 92px;
		width: auto;
	}

	.p-h-c-r-title {
		width: 92px;
		height: 14px;
		text-align: center;
		margin-bottom: 25px;
		border-right: 1px solid #00D0B0;
	}

	.p-h-c-r-title-c {
		color: #00D0B0;
	}

	.p-h-c-r-span {
		margin-left: 13px;
		width: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.p-h-c-r-item {
		height: 14px;
		line-height: 14px;
		font-size: 12px;
		margin-right: 27px;
	}

	.p-h-c-r-item-c {
		color: #00D0B0;
	}

	.ml60 {
		margin-left: 60px;
	}

	.p-head-class-name {
		text-align: center;
		overflow: hidden;
		width: 70px;
		margin-left: 10px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.p-theallheadclass {
		margin-top: -15px;
	}

	.p-theallheadlogin {
		margin-top: -26px;
	}
</style>

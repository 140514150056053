<template>
	<div style="overflow: hidden;">
		<div class="wrapper">
			<vhead ref="child"></vhead>
			<div class="content-box">
				<div class="content">
					<router-view></router-view>
				</div>
			</div>
			<vbottom ref="child0"></vbottom>
		</div>
	</div>
</template>

<script>
	import vbottom from './bottom.vue';
	import bus from './bus';
	import vhead from './vhead.vue';
	export default {
		data() {
			return {
				fisrtlogin:1,
				headclass:[]
			};
		},
		components: {
			vbottom,
			vhead,
		},
		methods: {
			getthe(){
				this.$get('goodscate/getgoodscatetreelist', {}).then(response => {
					if (response.error == 0) {
						this.headclass = response.data.goodsCateTreeList
						this.$refs.child.getgoodscate(this.headclass)
						this.$refs.child0.getgoodscate(this.headclass)
					} else {
						return
					}
				});
			}
		},
		created() {
			this.getthe()
			if ((this.$route.path == "/payWay"&& localStorage.getItem("token"))|| this.$route.path == "/courseDetail" || this.$route.path == "/microcoursedetail" ||
				(this.$route.path == "/organizeInfo" && localStorage.getItem("token")) || (this.$route.path == "/paySuccess" &&
					localStorage.getItem("token"))) {
				return
			}
			this.$router.push("/home")
		},
	};
</script>
